<template>
    <div>
        <slot name="default"></slot>
    </div>
</template>

<script>
export default {
    name: 'ui-input-option',

    inject: ['appendOption'],

    props: {
        value: {}
    },

    mounted() {
        this.appendOption({
            value: this.value,
            text: this.$el.innerHTML
        });
    }
}
</script>